import React, { Component } from 'react';
import { useNavigate, useLocation, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeading from '../../components/navigation/PageHeading';
import { requestGetWithToken, requestPostWithToken } from '../../axios';
import { InputText, InputSelect } from '../../components/common/Form';
import { FormAlertModal } from '../../components/common/Modal';

class SubmissionDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
			showModal: false,
			textModal: null
        }

		this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
        this.fetchData()
    }

    fetchData(){
        let params = {order_code: this.props.params.order_code}
        requestGetWithToken('v1/dashboard/transaction/submission/detail', params).then(response => {
            if (response.data.code === '00'){
                this.setState({data: response.data.data})
            }
            else {
                console.log(response.data.message)
            }
        }).catch(error => {
            console.log(error)
        })
    }

	statusBadge(status){
        let status_map = {
            'PENDING': 'PENDING',
            'REJECTED': 'TOLAK',
            'APPROVED': 'APPROVE BI CHECKING',
            'CONFIRMED': 'SELESAI PEMBAYARAN DP',
        }

        return status_map[status]
    }

	handleSubmit(e){
		e.preventDefault()
		let status = e.target.status.value

		if (!status){
			this.setState({showModal: true, textModal: 'Pilih status untuk update'})
		}
		else {
			var body = new FormData()
			body.append('id', this.state.data.id)
			body.append('status', status)

			requestPostWithToken('v1/dashboard/transaction/submission/status/update', body).then(response => {
				if (response.data.code === '00'){
					window.location.reload();
				}
				else {
					this.setState({showModal: true, textModal: response.data.message})
				}
			}).catch(error => {
				console.log(error)
			})
		}
		
		console.log(status)
	}

    formFormatInfo(){
        return [
            {'label': 'Order Code', 'name':'order_code', 'obj': 'string'},
            {'label': 'Status', 'name':'status', 'obj': 'string'},
            {'label': 'Tanggal Pengajuan', 'name':'created_at', 'obj': 'string'}
        ]
    }

    formFormatIdentity(){
        return [
            {'label': 'Nama Lengkap', 'name':'full_name', 'obj': 'string'},
            {'label': 'No KTP', 'name':'identification_no', 'obj': 'string'},
            {'label': 'Tanggal Lahir', 'name':'dob', 'obj': 'string'},
            {'label': 'Jenis Kelamin', 'name':'sex', 'obj': 'string'},
            {'label': 'Email', 'name':'email', 'obj': 'string'},
            {'label': 'Telepon', 'name':'phone', 'obj': 'string'},
            {'label': 'Alamat', 'name':'address', 'obj': 'string'},
            {'label': 'Provinsi', 'name':'province', 'obj': 'string'},
            {'label': 'Kota', 'name':'city', 'obj': 'string'},
        ]
    }
    
    formFormatVehicle(){
        return [
            {'label': 'Merk Mobil', 'name':'car_brand', 'obj': 'string'},
            {'label': 'Tipe Mobil', 'name':'car_type', 'obj': 'string'},
            {'label': 'Tenor (Tahun)', 'name':'tenor', 'obj': 'int'},
            {'label': 'Harga Mobil', 'name':'car_price', 'obj': 'int'},
            {'label': 'Down Payment', 'name':'dp_amount', 'obj': 'int'},
            {'label': 'Total Pinjaman', 'name':'total_debt', 'obj': 'int'},
            {'label': 'Bunga (%)', 'name':'interest', 'obj': 'float'},
            {'label': 'Angsuran / Bulan', 'name':'monthly_payment', 'obj': 'int'},
            {'label': 'Provisi', 'name':'provisi', 'obj': 'int'},
            {'label': 'Fiducia', 'name':'fiducia_fee', 'obj': 'int'},
            {'label': 'Credit Protection', 'name':'credit_protection', 'obj': 'int'},
            {'label': 'Pembayaran Pertama', 'name':'first_payment', 'obj': 'int'},
        ]
    }

    formFormatDocument(){
        return [
            {'label': 'Foto KTP', 'name':'ktp_image', 'obj': 'img'},
            {'label': 'Foto NPWP', 'name':'npwp_image', 'obj': 'img'},
        ]
    }

    updateOptions(){
        return [
			{'value': 'REJECTED', 'label': 'TOLAK'},
			{'value': 'APPROVED', 'label': 'APPROVE BI CHECKING'},
        ]
    }

    render() {
        return (
            <>
                <PageHeading title="Submission" />

                {this.state.data ? 
                <>
					<div className="card mb-3">
						<div className="card-body">
							<div className="col-12">
								<div className="row">
									{this.formFormatInfo().map(item => {
										return(
											<div key={item.name} className="col-4 form-group">
												<InputText 
													label={item.label} 
													defaultValue={item.name === 'status' ? this.statusBadge(this.state.data[item.name]) : this.state.data[item.name]} 
													readOnly={true} 
													obj={item.obj} />
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<div className="col-12">
								<div className="row">
									{this.formFormatIdentity().map(item => {
										return(
											<div key={item.name} className="col-4 form-group">
												<InputText 
													label={item.label} 
													defaultValue={this.state.data[item.name]} 
													readOnly={true} 
													obj={item.obj}/>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<div className="col-12">
								<div className="row">
									{this.formFormatVehicle().map(item => {
										return(
											<div key={item.name} className="col-4 form-group">
												<InputText 
													label={item.label} 
													defaultValue={this.state.data[item.name]} 
													readOnly={true} 
													obj={item.obj} />
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>

					<div className="card mb-3">
						<div className="card-body">
							<div className="col-12">
								<div className="row">
									{this.formFormatDocument().map(item => {
										return(
											<div key={item.name} className="col-3 form-group">
												<label className="form-label">{item.label}</label>
												<a href={this.state.data[item.name]} target="_blank" rel="noreferrer">
													<img src={this.state.data[item.name]} className="img-fluid" alt={item.label}/>
												</a>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
					
					{this.state.data.status == 'PENDING' ?
						<div className="card mb-3">
							<div className="card-body">
								<div className="col-12">
									<form id="formSubmit" onSubmit={this.handleSubmit}>
										<div className="row">
											<div className="col-10 form-group">
												<InputSelect 
													required={true}
													name="status" 
													label="Update Status" 
													options={this.updateOptions()} />
											</div>
											<div className="col-2 d-flex align-items-end form-group">
												<input type="submit" defaultValue="UPDATE" className="btn btn-primary btn-block" />
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					: null}
                </> 
                :null}

				<FormAlertModal show={this.state.showModal} text={this.state.textModal} handleCloseModal={() => this.setState({showModal: false})} />
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (props) => ({
    user: props.user.data
});

function SubmissionDetailFunction(props) {
    let navigate = useNavigate()
    let location = useLocation()
    let params = useParams()
    let [searchParams, setSearchParams] = useSearchParams()
    return <SubmissionDetail {...props} navigate={navigate} params={params} searchParams={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionDetailFunction);