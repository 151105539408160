import React, { Component } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateGlobal, userLogout } from '../../redux/actions';

class Topbar extends Component {
    logout(){
        this.props.logout()
        this.props.navigate('login', {replace: true})
    }

    render() {
        const { clickMenuOpen } = this.props;
		const { name, email } = this.props.user;

        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">

                <button onClick={() => { clickMenuOpen() }} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                    <i className="fa fa-bars"></i>
                </button>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown no-arrow">
                        <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
                            <span className="ml-2 d-none d-lg-inline text-gray-600 small">
								<div>{ name }</div>
								<small>{ email }</small>
							</span>
                        </a>

                        <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                            <a className="dropdown-item" href="#">
                                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                                Profile
                            </a>
                            <a className="dropdown-item" href="#">
                                <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                                Settings
                            </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" href="#" onClick={() => this.logout()}>
                                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        )
    }
}


const mapDispatchToProps = (dispatch) => ({
    logout() {
        dispatch(userLogout())
        dispatch(updateGlobal('user', []))
    }
})

const mapStateToProps = (props) => ({
    user: props.user.data
})

function TopbarFunction(props){
    let navigate = useNavigate()
    let location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams()
    return <Topbar {...props} navigate={navigate} params={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(TopbarFunction);