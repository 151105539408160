import React, { useState } from 'react';
import numeral from './Numeral';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Component } from 'react';


export function InputText(props){
    if (props.obj === 'int'){
        var defaultValue = numeral(props.defaultValue).format()
    }
    else if (props.obj === 'float'){
        defaultValue = numeral(props.defaultValue).format('0.00')
    }
    else {
        defaultValue = props.defaultValue
    }

    return(
        <>
            <label className="form-label">{props.label}</label>
            <input type="text"
                className="form-control"
                name={props.name}
                defaultValue={defaultValue}
                readOnly={props.readOnly} />
        </>
    )
}


export function InputSelect(props){
	var defaultValue = null

	props.options.map((item, i) => {
		if (item['value'] === props.defaultValue){
			defaultValue = item
		}
	})

    return(
        <>
            <label className="form-label">{props.label}</label>
            <Select
                defaultValue={defaultValue}
                name={props.name}
				isMulti={props.isMulti ? props.isMulti : false}
				options={props.options}
                onChange={props.onChange} />
        </>
    )
}

export class InputDate extends Component{
	constructor(props){
		super(props)
		this.state = {
			date: null
		}
	}

	componentDidMount(){
		if (this.props.defaultValue){
			this.setState({date: new Date(this.props.defaultValue)})
		}
	}

	setDate(value){
		this.setState({date: value})
	}

	render(){
		return (
			<>	
				<label className="form-label">{this.props.label}</label>	
				<DatePicker 
					className="form-control" 
					name={this.props.name}
					selected={this.state.date} 
					onChange={(date) => this.setDate(date)}
					dateFormat="yyyy-MM-dd" 
					minDate={this.props.minDate ? new Date(this.props.minDate) : null}
					maxDate={this.props.maxDate ? new Date(this.props.maxDate) : null}
					showYearDropdown
					showMonthDropdown
					yearItemNumber={5}
					dropdownMode="select"
					fixedHeight  />
			</>
		)
	}
}