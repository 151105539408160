import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Routes,  useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import Sidebar from '../../components/navigation/Sidebar';
import Topbar from '../../components/navigation/Topbar';
import Home from './Home';
import Submission from './Submission';
import SubmissionDetail from './SubmissionDetail';


class Index extends Component {
	componentDidMount(){
		this.checkUser()
	}

	checkUser() {
		if (!this.props.user.length === 0){
			this.props.navigate('login', {replace: true})
		}
	}

	render() {
		return (
			<>
				<div id="wrapper">
					<Sidebar />

					<div id="content-wrapper" className="d-flex flex-column">
						<div id="content">
							<Topbar />

							<div className="container-fluid">
								<Routes>
									<Route exact path="/" element={<Home />} />
									<Route path="submission">
                                        <Route exact path="list" element={<Submission />} />
									    <Route exact path="detail/:order_code" element={<SubmissionDetail />} />
                                    </Route>
								</Routes>
							</div>
						</div>
						
						<footer className="sticky-footer bg-white">
							<div className="container my-auto">
								<div className="copyright text-center my-auto">
									<span>Copyright &copy; Your Website 2019</span>
								</div>
							</div>
						</footer>
					</div>
				</div>

				<a className="scroll-to-top rounded" href="#page-top">
					<i className="fas fa-angle-up"></i>
				</a>
			</>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({

})

const mapStateToProps = (props) => ({
	user: props.user.data
});

function IndexFunction(props){
    let navigate = useNavigate()
    let location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams()
    return <Index {...props} navigate={navigate} params={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexFunction);