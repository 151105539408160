export const clickMenuOpen = () => ({
    type: 'CLICK_OPEN_MENU'
})

export const userLogout = () => ({
    type: 'LOGOUT'
})

export const updateGlobal = (type, value) => ({
    type: type.toUpperCase(),
    value: value
})
