import React, { Component } from 'react';
import { useLocation, useNavigate, useSearchParams, Link } from 'react-router-dom';

function ForgotPasswordContainer(props){
	let location = useLocation();
	let navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams()
    return <ForgotPassword {...props} navigate={navigate} location={location} params={searchParams} />
}

class ForgotPassword extends Component {
	render() {
		return (
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-sm-6">
						<div className="card border-0 shadow-lg my-5">
							<div className="card-body p-5">
								<div className="text-center">
									<h1 className="h4 text-gray-900 mb-4">Reset Password</h1>
								</div>
								<form onSubmit={this.handleSignIn} className="user">
									<div className="form-group">
										<input type="email" className="form-control form-control-user" id="exampleInputEmail" aria-describedby="emailHelp" placeholder="Email" />
									</div>
									<button type="submit" className="btn btn-primary btn-user btn-block">Reset</button>
								</form>
								<hr />
								<div className="text-center">
									<Link className="small" to="/login">Back to Login</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ForgotPasswordContainer;