import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { clickMenuOpen } from '../../redux/actions';
import logo from '../../assets/image/weplus.png';


class Sidebar extends Component {
	render() {
		const { toggleMenu, toggled } = this.props;
		console.log(this.props)
		return (
			<ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">
				<Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
					<div className="sidebar-brand-icon">
						<img src={logo} className="img-fluid" style={{maxWidth: 50}} />
					</div>
					<div className="sidebar-brand-text text-left mx-3">Partner Dashboard</div>
				</Link>

				<hr className="sidebar-divider my-0" />

                <li className="nav-item">
					<Link className="nav-link" to="submission/list">
						<i className="fas fa-fw fa-tachometer-alt mr-2"></i>
						<span>Car Financing</span>
					</Link>
				</li>

				{/* <li className="nav-item">
					<a className='nav-link collapsed' href="#" data-toggle="collapse" data-target="#collapseTwo" aria-controls="collapseTwo">
						<i className="fas fa-fw fa-cog"></i>
						<span>Components</span>
					</a>
					<div id="collapseTwo" className='collapse' aria-labelledby="headingTwo" data-parent="#accordionSidebar">
						<div className="bg-white py-2 collapse-inner rounded">
							<h6 className="collapse-header">Custom Components:</h6>
							<a className="collapse-item" href="buttons.html">Buttons</a>
							<Link className="collapse-item" to="/cards">Cards</Link>
						</div>
					</div>
				</li>

				<li className="nav-item">
					<a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
						<i className="fas fa-fw fa-wrench"></i>
						<span>Utilities</span>
					</a>
					<div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
						<div className="bg-white py-2 collapse-inner rounded">
							<h6 className="collapse-header">Custom Utilities:</h6>
							<a className="collapse-item" href="utilities-color.html">Colors</a>
							<a className="collapse-item" href="utilities-border.html">Borders</a>
							<a className="collapse-item" href="utilities-animation.html">Animations</a>
							<a className="collapse-item" href="utilities-other.html">Other</a>
						</div>
					</div>
				</li>

				<li className="nav-item">
					<Link className="nav-link" to="/charts">
						<i className="fas fa-fw fa-chart-area"></i>
						<span>Charts</span>
					</Link>
				</li>

				<li className="nav-item">
					<a className="nav-link" href="tables.html">
						<i className="fas fa-fw fa-table"></i>
						<span>Tables</span></a>
				</li> */}

				<div className="text-center d-none d-md-inline">
					<button onClick={() => toggleMenu()} className="rounded-circle border-0" id="sidebarToggle"></button>
				</div>
			</ul>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	toggleMenu(value){
		dispatch(clickMenuOpen(value))
	}
})

const mapStateToProps = (props) => ({
	toggled: props.menuState.data
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);