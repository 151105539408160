import { combineReducers } from 'redux';

const menuState = {
    data: false
}

const initialState = {
    data: [],
    isFinish: false,
}

const MenuOpenReducer = (state = menuState, action) => {
    switch (action.type) {
        case 'CLICK_OPEN_MENU':
            return {
                ...state,
                data: !state.data
            };
        default:
            return state
    }
}

const UserLogout = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            localStorage.removeItem('persistantState')
            return state
        
        default:
            return state
    }
}

const UpdateData = (service) => {
    const serviceReducer = {
        [service]: (state = initialState, action) => {
            switch (action.type) {
                case `${service}`:
                    return {
                        ...state,
                        data: action.value,
                        isFinish: true
                    };
                default:
                    return state;
            }
        }
    }
    return serviceReducer[service]
}

export const Reducers = combineReducers({
    menuState: MenuOpenReducer,
    logout: UserLogout,
    user: UpdateData('USER'),
    token: UpdateData('TOKEN'),
})