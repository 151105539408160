import { Component } from "react"


export default class Pagination extends Component{
	constructor(props){
		super(props)
		this.state = {
			pages: null
		}
	}

	componentDidMount(){
		var pages = []
		let current = this.props.page
		let prev = current - 2 < 1 ? 1 : current - 2
		let url = this.props.url.pathname + this.props.url.search.replace(`&page=${this.props.page}`, '').replace(`?page=${this.props.page}`, '')
		
		for (let i = prev; i <= prev + 3; i++){
			if (i <= this.props.total_pages){
				if (url.includes('?')){
					pages.push({'page': i, 'url': url + `&page=${i}` })
				}
				else {
					pages.push({'page': i, 'url': url + `?page=${i}` })
				}
				
			}
		}

		this.setState({pages: pages, url: url})
	}

	render(){
		return(
			<>
			{this.state.pages && this.state.pages.length > 1?
				<nav aria-label="Page navigation example">
					<ul className="pagination justify-content-end">
						<li className={parseInt(this.props.page) === 1 ? "page-item disabled": "page-item"}>
							<a className="page-link" href={this.state.url + `&page=${parseInt(this.props.page)-1}`}>Prev</a>
						</li>

						{this.state.pages.map((item, i) => {
							return(
								<li key={i} className={item.page === parseInt(this.props.page) ? "page-item active": "page-item" }>
									<a className="page-link" href={item.url}>{item.page}</a> 
								</li>
							)
						})}

						<li className={parseInt(this.props.page) === parseInt(this.props.total_pages) ? "page-item disabled": "page-item"}>
							<a className="page-link" href={this.state.url + `&page=${parseInt(this.props.page)+1}`}>Next</a>
						</li>
					</ul>
				</nav>
			: null}
			</>
		)
	}
}