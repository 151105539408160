import React, { Component } from 'react';
import { useNavigate, useLocation, useSearchParams, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeading from '../../components/navigation/PageHeading';
import Pagination from '../../components/navigation/Pagination';
import { requestGetWithToken } from '../../axios';
import numeral from '../../components/common/Numeral';
import FormFilter from '../../components/common/Filter';


class Submission extends Component {
    constructor(props){
        super(props)
        this.state = {
            data: null,
			page: 1
        }
    }

    componentDidMount(){
        this.fetchData()
		this.currentPage()
    }

    fetchData(){
        let params = new URLSearchParams()
        for (var x of this.props.searchParams.entries()){
            params.append(x[0], x[1])
        }

        requestGetWithToken('v1/dashboard/transaction/submission/list', params).then(response => {
            if (response.data.code === '00'){
                this.setState({data: response.data})
            }
            else {
                console.log(response.data.message)
            }
        }).catch(error => {
            console.log(error)
        })
    }

	currentPage(){
		this.setState({page: this.props.searchParams.get('page') ? this.props.searchParams.get('page') : 1})
	}

    statusBadge(status){
        let status_map = {
            'PENDING': <span className="badge badge-secondary">PENDING</span>,
            'REJECTED': <span className="badge badge-danger">TOLAK</span>,
            'CONFIRMED': <span className="badge badge-primary">SELESAI PEMBAYARAN DP</span>,
            'APPROVED': <span className="badge badge-success">APPROVE BI CHECKING</span>,
        }
        return status_map[status]
    }

    filterFormat(){
        return [
            {'label': 'Nama Lengkap', 'name': 'name', 'type': 'text'},
            {'label': 'Order Code', 'name': 'order_code', 'type': 'text'},
            {'label': 'Email', 'name': 'email', 'type': 'text'},
            {'label': 'Phone', 'name': 'phone', 'type': 'text'},
            {'label': 'Status', 'name': 'status', 'type': 'select', 'multi': false, 'options': [
                {'value': '', 'label': 'SEMUA'},
                {'value': 'PENDING', 'label': 'PENDING'},
                {'value': 'REJECTED', 'label': 'TOLAK'},
				{'value': 'APPROVED', 'label': 'APPROVE BI CHECKING'},
                {'value': 'CONFIRMED', 'label': 'SELESAI PEMBAYARAN DP'},
            ]},
            {'label': 'Pengajuan Setelah', 'name': 'start_date', 'type': 'date'},
            {'label': 'Pengajuan Sebelum', 'name': 'end_date', 'type': 'date'},
        ]
    }

    filterSubmit(e){
        let params = {}
        for (var x=0; x < e.target.length; x++){
            if (e.target[x].getAttribute('name') != null){
                params[e.target[x].getAttribute('name')] = e.target[x].value
            }
        }
        
        this.props.setParams(params)
    }

    render() {

        return (
            <>
                <PageHeading title="Submission" />	

                <FormFilter {...this.props} format={this.filterFormat()} onSubmit={this.filterSubmit} />

				{this.state.data ?
					<div className="card">
						<div className="card-body">
							<Pagination page={this.state.page} total_pages={this.state.data.total_pages} url={this.props.location} />

							<div className="table-responsive">
								<table className="table table-bordered table-hover">
									<thead className="thead-light"> 
										<tr>
											<th className="text-center">No</th>
											<th className="text-center">Order Code</th>
											<th className="text-center">Nama</th>
											<th className="text-center">Email</th>
											<th className="text-center">Telepon</th>
											<th className="text-center">Tenor</th>
											<th className="text-center">Total Pinjaman</th>
											<th className="text-center">Tanggal</th>
											<th className="text-center">Status</th>
										</tr>
									</thead>
									<tbody>
										{this.state.data.data ?
											this.state.data.data.map((item, i) => {
												return(
													<tr key={item.order_code}>
														<td className="text-center">{i + 1}</td>
														<td className="text-center">
															<Link to={`/submission/detail/${item.order_code}`} target="_blank" rel="noopener noreferrer">{item.order_code}</Link>
														</td>
														<td className="text-center">{item.user_name}</td>
														<td className="text-center">{item.user_email}</td>
														<td className="text-center">{item.user_phone}</td>
														<td className="text-center">{item.tenor}</td>
														<td>Rp <span className="float-right">{numeral(item.total_debt).format()}</span></td>
														<td className="text-center">{item.created_at}</td>
														<td className="text-center">{this.statusBadge(item.status)}</td>
													</tr>
												)
											})
										: null }
									</tbody>
								</table>
							</div>

							<Pagination page={this.state.page} total_pages={this.state.data.total_pages} url={this.props.location} />
						</div>
					</div>
				: null}
            </>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (props) => ({
	user: props.user.data
});

function SubmissionFunction(props){
    let navigate = useNavigate()
    let location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams()
    return <Submission {...props} navigate={navigate} searchParams={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionFunction);