import { InputText, InputSelect, InputDate } from "./Form";


export default function FormFilter(props){
	return(
		<div className="card mb-3">
			<div className="card-body">
				<form id="filterForm" onSubmit={props.onSubmit} autoComplete="off">
					<div className="row">
						{props.format.map(item => {
							return(
								<div key={item.name} className="col-3 form-group">
									{item.type === 'text' ?
										<InputText 
											name={item.name} 
											label={item.label}
											readOnly={false}
											defaultValue={props.searchParams.get(item.name)} />
									
									:item.type === 'select' ?
										<InputSelect
											name={item.name} 
											label={item.label}
											isMulti={item.multi}
											options={item.options} 
											defaultValue={props.searchParams.get(item.name)} />
									: item.type === 'date' ?
										<InputDate 
											name={item.name} 
											label={item.label}
											minDate={"2018-06-01"}
											maxDate={new Date()}
											defaultValue={props.searchParams.get(item.name)} />

									: null}
								</div>
							)
						})}
						<div className="col-12 form-group text-right">
							<input type="submit" className="btn btn-primary" />
						</div>
					</div>
				</form>
			</div>
		</div>
	)
}