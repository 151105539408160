import React, { Component } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeading from '../../components/navigation/PageHeading';

class Home extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }
    
    componentDidMount(){
        this.checkUser()
    }

    checkUser(){
        if(this.props.user.length === 0){
            this.props.navigate('login', {replace: true})
        }
    }

	render() {
		return (
			<>
				<PageHeading title="Dashboard" />	
				<div className="card shadow">
					<div className="card-body">
						<div className="table-responsive">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>No</th>
										<th>Col 1</th>
										<th>Col 2</th>
										<th>Col 3</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>No</td>
										<td>Col 1</td>
										<td>Col 2</td>
										<td>Col 3</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (props) => ({
	user: props.user.data,
	token: props.token.data,
});

function HomeFunction(props){
    let navigate = useNavigate()
    let location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams()
    return <Home {...props} navigate={navigate} params={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeFunction);