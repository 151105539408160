import axios from 'axios';
import { API_URL } from '../config';

function requestGet(endpoint, params=null) {
	let config = {
		method: "get",
		url: `${ API_URL }/${ endpoint }/`,
		params: params
	}
	return axios(config)
}

function requestGetWithToken(endpoint, params=null) {
    let token = JSON.parse(localStorage.getItem('persistantState'))['token']['data']

    let config = {
		method: "get",
		url: `${ API_URL }/${ endpoint }/`,
		params: params,
        headers: {
			'Authorization': `Token ${token}`,
            'Content-type': 'application/x-www-form-urlencoded'
		}
	}
	return axios(config)
}

function requestPost(endpoint, data){	
	let config = {
		method: "post",
		url: `${ API_URL }/${ endpoint }/`,
		data: data,
		headers: {
			'Content-Type': 'application/json',
		}
	}
	return axios(config)
}

function requestPostWithToken(endpoint, data){	
    let token = JSON.parse(localStorage.getItem('persistantState'))['token']['data']

	let config = {
		method: "post",
		url: `${ API_URL }/${ endpoint }/`,
		data: data,
		headers: {
			'Content-type': 'application/json',
            'Authorization': `Token ${token}`
		}
	}
	return axios(config)
}

export {
	requestGet,
	requestPost,
    requestGetWithToken,
    requestPostWithToken
}