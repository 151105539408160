import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/login/Login';
import ForgotPassword from './pages/login/ForgotPassword';
import Index from './pages/dashboard';

class App extends Component {
	render() {
		return (
			<div>
				<Routes>
					<Route exact path="login" element={<Login />} />
					<Route exact path="forgot-password" element={<ForgotPassword />} />
					<Route path="/*" element={<Index />} />
				</Routes>	
			</div>
		);
	}
}

export default App;
