import React, { Component } from 'react';
import { Link, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { FormAlertModal } from '../../components/common/Modal';
import { requestPost } from '../../axios';
import { connect } from 'react-redux';
import { updateGlobal } from '../../redux/actions';


class Login extends Component {
	constructor(props){
		super(props)
		this.state = {
			alert: null,
			showModal: false
		}
	}

	handleSignIn = (e) => {
		e.preventDefault()
		let form = e.target
		let validate = this.formValidation(form)

		if(validate){
			let body = new FormData()
			body.append('email', form.email.value)
			body.append('password', form.password.value)
			requestPost('v1/dashboard/user/login', body).then(response => {
				if(response.data.code === '00'){
                    console.log(response.data.data)
					this.props.loginUser(response.data.data)
                    this.props.navigate('/', {replace: true})
				}
				else {
					this.setState({showModal: true, alert: response.data.message})
				}
			})
		}
	}

	formValidation(form){
		if(!form.email.value){
			this.setState({showModal: true, alert: 'Masukan email'})
		}
		else if(!form.password.value){
			this.setState({showModal: true, alert: 'Masukan password'})
		}
		else {
			return true
		}

		return false
	}

	render() {
        console.log(this.props)
		return (
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-sm-6">
						<div className="card border-0 shadow-lg my-5">
							<div className="card-body p-5">
								<div className="text-center">
									<h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
								</div>
								<form onSubmit={this.handleSignIn} className="user">
									<div className="form-group">
										<input type="email" className="form-control form-control-user" id="email" placeholder="Email" />
									</div>
									<div className="form-group">
										<input type="password" className="form-control form-control-user" id="password" placeholder="Password" />
									</div>
									<button type="submit" className="btn btn-primary btn-user btn-block">Login</button>
								</form>
								<hr />
								<div className="text-center">
									<Link className="small" to="/forgot-password">Forgot Password</Link>
								</div>
							</div>
						</div>
					</div>
				</div>

				<FormAlertModal  
					text={this.state.alert} 
					show={this.state.showModal} 
					handleCloseModal={() => this.setState({showModal: false})} />
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	loginUser(value){
		dispatch(updateGlobal('user', value))
		dispatch(updateGlobal('token', value.token))
	}
})

const mapStateToProps = (props) => ({
	user: props.user.data
});

function LoginFunction(props){
    let navigate = useNavigate()
    let location = useLocation()
    let [searchParams, setSearchParams] = useSearchParams()
    return <Login {...props} navigate={navigate} params={searchParams} setParams={setSearchParams} location={location} />
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginFunction);